import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import IImageQueryResult from "../IImageQuery";
import "./template.css";

interface ITemplateProps{
  heroSnippet?: React.ReactNode;
}
export default class Template extends React.Component<ITemplateProps> {
    private renderCallbackFunc: (data: IImageQueryResult) => React.ReactNode = this.renderCallback.bind(this);
    public render(): JSX.Element {
        return <StaticQuery query={graphql`
  query {
    placeholderImage: file(relativePath: { eq: "IMG_1621.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3991) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`}
            render={this.renderCallbackFunc} />;
    }

    private renderCallback(data: IImageQueryResult): React.ReactNode {
        const imageSrc = data.placeholderImage.childImageSharp.fluid ? data.placeholderImage.childImageSharp.fluid.src : "";
        const imageBackground = "url('" + imageSrc + "')";
        return <Layout>
            <section className="hero-image" style={{ 
                backgroundImage: imageBackground
              }}>                
                    {this.props.heroSnippet}
            </section>
            <section id={"body_text"}>
              {this.props.children}
            </section>
        </Layout>
    }
}
