import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'gatsby';
import React from "react"
import SEO from "../components/seo";
import Template from "../components/template";


export default function IndexPage(): JSX.Element {

  return <Template
      heroSnippet={
        <div>
      <Link to={"#body_text"}><FontAwesomeIcon icon={faArrowCircleDown} size={"8x"} /></Link>
      </div>}
    >
    <SEO title={"Home"} />
    <main style={{
      margin: "0 auto",    
      maxWidth:"960px",
      padding:"3em"
    }}>
      <article>
        <h1>To Foster</h1>
        <p>
        I am feeling extremely sleepy. That isn’t unusual for me, I have been sleeping a lot lately. Ever since Mom and the Big One stopped taking me on walks all the time, sleep has been the main thing I do. Mom is crying, and the Big One is holding her hand. I should get up and do something about it – Mom needs comforting – but I am just so tired, and my legs aren’t working. Something is wrong, and I am struggling to stay awake. I think I should lay down and take a long nap. Everything is always better after a nap.
        </p>
        <a href={"https://medium.com/whoisidaho/to-foster-19ebdbeb85b4"}>Read More...</a>
      </article>
    </main>
  </Template>;
}
